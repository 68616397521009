<template>
  <div>
    <!-- 产品中心顶部banner组件 -->
    <product-banner></product-banner>
    <!-- 产品中心标题组件 -->
    <product-title></product-title>
    <!-- 产品中心二级路由出口 -->
    <router-view></router-view>
  </div>
</template>

<script>
import productBanner from "./components/product-banner.vue";
import ProductTitle from "./components/product-title.vue";
export default {
  components: { productBanner, ProductTitle },
};
</script>

<style lang="less" scoped>
</style>