<template>
  <div class="product_title">
    <h3>{{ this.$route.query.title }}</h3>
    <p>{{ this.$route.query.desc }}</p>
    <div class="intro">
      <div class="info">简介</div>
      <div class="tri"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.product_title {
  position: relative;
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #302874;
  // background-color: #d2d2d7;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: url("~@/assets/img/product/intro_2.jpg") no-repeat center/cover;
  h3 {
    font-size: 50px;
    color: #302874;
    font-weight: 700;
  }
  p {
    font-size: 25px;
    color: #4fbdf6;
    text-transform: uppercase;
  }
  .intro {
    position: absolute;
    right: 30px;
    bottom: 0;
    height: 80px;
    width: 150px;
    background-color: #30287490;
    .info {
      padding-top: 8px;
      text-align: center;
      font-size: 45px;
      color: #fff;
    }
    .tri {
      position: absolute;
      top: 62px;
      left: 100px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 20px 20px 20px;
      border-color: transparent transparent #fff transparent;
    }
  }
}
</style>